import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/store/project';
import { useArticleStore } from '@/store/article';
import { useAccountStore } from '@/store/account';
import pinia from '@/store/store';

const project = useProjectStore(pinia);
const article = useArticleStore(pinia);
const account = useAccountStore(pinia);
const { data, selections, activeRoof, roofIndex, activeExtension, extensionIndex, calc_tile, calculated, id, shorturl, loading, market, getMarket } =
  storeToRefs(project);
const { cache } = storeToRefs(article);
const { getRoofMeasurements } = project;
const { getLanguage } = storeToRefs(account);

export {
  project,
  data,
  selections,
  activeRoof,
  roofIndex,
  activeExtension,
  extensionIndex,
  calc_tile,
  calculated,
  id,
  shorturl,
  cache as articles,
  loading,
  getRoofMeasurements,
  market,
  getLanguage,
  getMarket,
};
