import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useLoadingStore = defineStore({
  id: 'loading',
  state: () => ({
    loadingTracker: {} as Record<string, boolean>,
  }),
  getters: {
    watchLoading(state) {
      return (key: string) => {
        // Create loading state if it doesn't exist
        if (!(key in state.loadingTracker)) {
          state.loadingTracker[key] = false;
        }

        // Return a computed property for reactivity
        return computed(() => state.loadingTracker[key]);
      };
    },
  },
  actions: {
    setLoading(key: string, value: boolean) {
      this.loadingTracker[key] = value;
    },
  },
});
